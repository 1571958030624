import React, { useState } from "react";
import contactImage from "../assets/images/contact-us.jpg"; // Update this path to your image
import { toast } from "react-toastify";
import UserService from "../services/userService";
import { ClipLoader } from "react-spinners";
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_RECAPTCH_KEY } from "../config/AppConfig";

const ContactUs = () => {
  const [loading, isLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone_number: "",
    email: "",
    subject: "",
    message: "",
  });
  const [captchaValue, setCaptchaValue] = useState(null); // State for CAPTCHA value

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (!formData.email.trim()) {
      toast.error("Please enter email.");
      return;
    } else if (!validateEmail(formData.email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    if (!captchaValue) {
      toast.error("Please complete the CAPTCHA.");
      return;
    }

    isLoading(true);
    await UserService.ContactUs(formData)
      .then((response) => {
        console.log("response", response);
        setTimeout(() => {
          toast.success("Form submitted successfully.");
          setFormData({
            name: "",
            phone_number: "",
            email: "",
            subject: "",
            message: "",
          });
          setCaptchaValue(null); // Reset CAPTCHA
          isLoading(false);
        }, 1500);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value); // Set CAPTCHA value when completed
  };

  return (
    <div className="flex flex-col lg:flex-row lg:justify-between p-12 max-w-7xl mx-auto">
      <div className="lg:w-1/2 mb-8">
        <h1 className="text-4xl font-bold mb-6">Contact Us</h1>
        <p>
          Hi there! Do you need an estimate for your project? We can help! Our
          experts can provide you with a free and customized estimate that suits
          your specific needs.
        </p>
        <form className="py-6 rounded-lg">
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="phone">
              Phone Number
            </label>
            <input
              type="tel"
              id="phone"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="subject">
              Subject
            </label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="message">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="4"
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            ></textarea>
          </div>
          {/* ReCAPTCHA */}
          <div className="mb-4">
            <ReCAPTCHA
              sitekey={GOOGLE_RECAPTCH_KEY} // Replace with your site key
              onChange={handleCaptchaChange}
            />
          </div>
          <button
            type="submit"
            className={`bg-[#687ADA] text-white py-2 px-4 rounded-md  transition duration-300 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={(e) => handleSubmitForm(e)}
            disabled={loading || !captchaValue} // Disable if loading or CAPTCHA is not completed
          >
            {loading ? <ClipLoader /> : "Submit Now"}
          </button>
        </form>
      </div>

      {/* Right Side: Contact Image */}
      <div className="lg:w-1/2 flex items-center">
        <img
          src={contactImage}
          alt="Contact Us"
          className="mx-auto w-auto h-3/5 object-fit rounded-lg"
        />
      </div>
    </div>
  );
};

export default ContactUs;
