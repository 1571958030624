import fetch from "../interceptor/fetchInterceptor";

const UserService = {};

UserService.ContactUs = function (data) {
  return fetch({
    url: "/add/contactus",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};
export default UserService;
