import React, { useEffect, useState } from "react";
import appImage from "../assets/images/home-top.jpg";
import portfolioImage from "../assets/images/dp-portfolio.jpg";
import portfolioImage1 from "../assets/images/CloudConsultingServices.jpg";
import portfolioImage2 from "../assets/images/AIConsultingServices.jpg";
import portfolioImage3 from "../assets/images/ManagedCloudandContainerbasedServices.jpg";
import portfolioImage4 from "../assets/images/CloudInfrastructureAutomation.jpg";
import portfolioImage5 from "../assets/images/CloudandAISecurity.jpg";
import portfolioImage6 from "../assets/images/dp-portfolio-06.jpg";
import aboutImage from "../assets/images/home-about.jpg";
import {
  FaCode,
  FaComments,
  FaDatabase,
  FaLifeRing,
  FaShoppingCart,
} from "react-icons/fa";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const HomePage = () => {
  const [activeTab, setActiveTab] = useState("All Projects");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if there are success or error parameters in the URL
    const params = new URLSearchParams(location.search);
    const success = params.get("success");
    const error = params.get("error");

    if (success) {
      localStorage.setItem("authenticated", true);
      toast.success(success);
      setTimeout(() => {
        const urlWithoutParams = window.location.pathname; // Remove query params
        window.history.replaceState({}, document.title, urlWithoutParams);
        window.location.reload();
      }, 1500);
    }

    if (error) {
      toast.error(error);
      const urlWithoutParams = window.location.pathname;
      window.history.replaceState({}, document.title, urlWithoutParams);
    }
  }, [location]);

  const handleRedirectContactus = (e) => {
    e.preventDefault();
    navigate("/contact");
  };

  const allPortfolioItems = [
    {
      id: 1,
      subtitle: "Cloud Consulting Services & Solutions",
      title:
        "Maximize your business potential with customized cloud strategies and solutions that drive innovation, scalability, and operational efficiency. Partner with us to accelerate your cloud journey and achieve transformative results.",
      image: portfolioImage1,
    },
    {
      id: 2,
      subtitle: "AI Consulting Services",
      title:
        "Leverage the power of artificial intelligence to gain a competitive edge. Our AI consulting services empower you to integrate intelligent automation and advanced analytics, enabling smarter decisions and business transformation.",
      image: portfolioImage2,
    },
    {
      id: 3,
      subtitle: "Managed Cloud and Container based Services",
      title:
        "Ensure seamless, reliable operations with our fully managed cloud and container services. From deployment to continuous optimization, we handle the complexities so you can focus on what matters growing your business.",
      image: portfolioImage3,
    },
    {
      id: 4,
      subtitle: "Cloud Infrastructure Automation",
      title:
        "Streamline your IT operations with end-to-end cloud infrastructure automation. Our solutions deliver faster deployments, reduced operational risks, and consistent performance, ensuring your business stays agile and competitive.",
      image: portfolioImage4,
    },
    {
      id: 5,
      subtitle: "Cloud Security and AI Security",
      title:
        "Protect your business with advanced cloud and AI security solutions. We offer comprehensive protection for your data, applications, and infrastructure, safeguarding you from evolving threats while ensuring compliance and peace of mind.",
      image: portfolioImage5,
    },
    // {
    //   id: 6,
    //   title: "Getting tickets to the big show",
    //   subtitle: "Mobile App",
    //   image: portfolioImage6,
    // },
  ];

  const filteredItems =
    activeTab === "All Projects"
      ? allPortfolioItems
      : allPortfolioItems.filter((item) => item.subtitle === activeTab);

  const tabs = [
    { name: "All Projects" },
    { name: "Web Design" },
    { name: "Logo Design" },
    { name: "Mobile App" },
  ];

  const services = [
    {
      id: 1,
      icon: <HiOutlinePencilSquare className="text-5xl mb-2" />,
      title: "Design",
      description:
        "Creating visually appealing designs that captivate audiences.",
    },
    {
      id: 2,
      icon: <FaCode className="text-5xl mb-2" />,
      title: "Software Development",
      description: "Building robust software solutions tailored to your needs.",
    },
    {
      id: 3,
      icon: <FaComments className="text-5xl mb-2" />,
      title: "Consulting",
      description:
        "Providing expert advice to enhance your business performance.",
    },
    {
      id: 4,
      icon: <FaDatabase className="text-5xl mb-2" />,
      title: "Data & AI",
      description: "Leveraging data analytics and AI to drive smart decisions.",
    },
    {
      id: 5,
      icon: <FaShoppingCart className="text-5xl mb-2" />,
      title: "eCommerce",
      description:
        "Developing effective eCommerce platforms for your business.",
    },
    {
      id: 6,
      icon: <FaLifeRing className="text-5xl mb-2" />,
      title: "Help & Support",
      description:
        "Offering reliable support and assistance whenever you need.",
    },
  ];

  return (
    <div>
      {/* Top Section */}
      <section className="flex flex-col lg:flex-row items-center justify-between lg:py-48 py-8 lg:px-24 px-12 bg-gray-50 gap-4">
        {/* Left Side: Rounded Image */}
        <div className="lg:w-1/2 w-full flex justify-center lg:justify-center">
          <img
            src={appImage}
            alt="App Related"
            className="rounded-full lg:h-[30rem] lg:w-[30rem] h-[20rem] w-[20rem] object-cover shadow-2xl border-[0.5rem] border-[#687ADA]"
          />
        </div>

        {/* Right Side: Content */}
        <div className="lg:w-1/2 w-full mt-8 lg:mt-0">
          <h1 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-6 leading-relaxed">
            Connecting You to the World Through Technology
          </h1>
          <p className="text-base lg:text-lg text-gray-600 mb-4 text-justify leading-loose">
            We help to integrate technology for personal or business use. Our
            slogan highlights the significance of technology, and we strengthen
            our bond with clients by connecting them to the world.
          </p>
          <button
            className="mt-6 bg-[#687ADA] text-white px-6 py-3 rounded-lg hover:bg-[#5a69c1] transition lg:text-xl text-lg"
            onClick={(e) => handleRedirectContactus(e)}
          >
            Contact us
          </button>
        </div>
      </section>

      {/* Portfolio Section */}
      <section className="py-32 lg:px-24 px-12">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-24 w-max mx-auto">
          Portfolio
          <p className="h-1 bg-[#687ADA] mx-auto w-16 mt-2"></p>
        </h2>

        {/* Tabs Navigation */}
        {/* <div className="flex justify-center space-x-4 mb-12">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              className={`px-4 py-2 transition duration-300 ${
                activeTab === tab.name
                  ? "border-b-2 border-[#687ADA] text-[#687ADA]"
                  : "hover:bg-[#B7C9EB]"
              }`}
              onClick={() => setActiveTab(tab.name)}
            >
              {tab.name}
            </button>
          ))}
        </div> */}

        {/* Portfolio Items */}
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-8 transition-transform duration-300 content-center">
          {filteredItems.map((item) => (
            <div
              key={item.id}
              className="flex flex-col items-center bg-white rounded-lg overflow-hidden transition-transform duration-300 hover:-translate-y-6"
            >
              {/* Image */}
              <div className="overflow-hidden">
                <img
                  src={item.image}
                  alt={item.title}
                  className="h-72 min-w-48 object-cover rounded-lg cursor-pointer border border-[0.5rem] border-[#687ADA]"
                />
              </div>
              {/* Content */}
              <div className="p-6">
                <p className="text-xl text-gray-800 font-bold lg:text-left text-center">
                  {item.subtitle}
                </p>
                <h3 className=" text-gray-600 mb-2 transition hover:text-[#687ADA] cursor-pointer w-full text-center lg:text-left">
                  {item.title}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* About Section */}
      <section className="py-32 lg:px-24 px-12 bg-gray-50">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">
          About Us
          <p className="h-1 bg-[#687ADA] mx-auto w-16 mt-2"></p>
        </h2>

        <div className="flex flex-col lg:flex-row items-center justify-between gap-4">
          {/* Left Side: Text Content */}
          <div className="lg:w-1/2 w-full mb-8 lg:mb-0">
            <h3 className="lg:text-3xl text-2xl font-semibold text-gray-800 mb-4">
              Leadership
            </h3>
            <p className="text-base text-gray-600 mb-4 leading-loose lg:text-justify">
              We offer reliable and efficient software services that are
              tailored to meet your unique needs. Our team of highly skilled
              professionals provides support from initial consultation to
              implementation and beyond, ensuring that your operations run
              smoothly. Contact us today for unparalleled software services.
            </p>
            <button
              className="mt-4 bg-[#687ADA] text-white px-6 py-3 rounded-lg hover:bg-[#5a69c1] transition text-base"
              onClick={(e) => handleRedirectContactus(e)}
            >
              Contact us
            </button>
          </div>

          {/* Right Side: Image */}
          <div className="lg:w-1/2 w-full flex justify-center">
            <img
              src={aboutImage}
              alt="About Us"
              className="rounded-lg shadow-lg h-96 w-auto border-[0.5rem] border-[#687ADA]"
            />
          </div>
        </div>
      </section>

      <section className="py-32 lg:px-24 px-12">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">
          Our Services
          <p className="h-1 bg-[#687ADA] mx-auto w-16 mt-2"></p>
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service) => (
            <div
              key={service.id}
              className="group bg-[#F6F6F6] rounded-lg p-8 shadow-md hover:shadow-lg transition-transform duration-300 hover:-translate-y-6 cursor-pointer hover:bg-[#687ADA] hover:text-white"
            >
              {/* Icon */}
              <div className="flex hover:text-white">
                {/* Change icon color on hover */}
                <span className="text-[#687ADA] group-hover:text-white transition-colors duration-300">
                  {service.icon}
                </span>
              </div>
              {/* Title */}
              <h3 className="text-lg font-bold mt-4 mb-2">{service.title}</h3>
              {/* Description */}
              <p className="text-base">{service.description}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default HomePage;
