import fetch from "../interceptor/fetchInterceptor";

const JwtAuthService = {};

JwtAuthService.socialLogin = function (data) {
  return fetch({
    url: "/user/social/login",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

JwtAuthService.ForgetPassword = function (data) {
  return fetch({
    url: "/user/forget/password",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

JwtAuthService.login = function (data) {
  return fetch({
    url: "/user/login",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

JwtAuthService.Register = function (data) {
  return fetch({
    url: "/user/register",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};
export default JwtAuthService;
