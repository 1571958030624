import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import JwtAuthService from "../services/authService";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { ClipLoader } from "react-spinners";
import { GOOGLE_RECAPTCH_KEY } from "../config/AppConfig";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [captchaValue, setCaptchaValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({});

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value); // Set CAPTCHA value when completed
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    // Validate email
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      JwtAuthService.ForgetPassword(formData)
        .then((response) => {
          console.log("response = ", response);
          if (response.status) {
            toast.success(response.message);
            setLoading(false);
            setTimeout(() => {
              navigate("/login");
            }, 1500);
          }
        })
        .catch((error) => {
          console.log("error = ", error);
          if (error.message) {
            toast.error(error.message);
            setLoading(false);
          }
        });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 px-4">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
        <h2 className="text-3xl font-bold mb-6 text-center">Forget Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={`w-full px-4 py-2 border ${
                errors.email ? "border-red-500" : "border-gray-300"
              } rounded-lg focus:outline-none focus:ring-2 focus:ring-[#687ADA]`}
              placeholder="Enter your email"
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">{errors.email}</p>
            )}
          </div>
          <div className="mb-4">
            <ReCAPTCHA
              sitekey={GOOGLE_RECAPTCH_KEY} // Replace with your site key
              onChange={handleCaptchaChange}
            />
          </div>
          <button
            type="submit"
            className={`w-full bg-[#687ADA] text-white py-2 rounded-lg  transition duration-300 ${
              !captchaValue
                ? "cursor-not-allowed"
                : "cursor-pointer hover:bg-[#5a69c1]"
            }`}
            disabled={!captchaValue}
          >
            {loading ? <ClipLoader /> : "Submit"}
          </button>
        </form>
        <p className="mt-6 text-center">
          Already have an account?{" "}
          <Link to="/login" className="text-[#687ADA] hover:underline">
            Login
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ForgetPassword;
