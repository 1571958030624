// import React from "react";
// import product1 from "../assets/images/product1.jpg"
// import product2 from "../assets/images/product2.jpg"
// import product3 from "../assets/images/product3.jpg"
// import product4 from "../assets/images/product4.jpg"
// import product5 from "../assets/images/product5.jpg"
// // Dummy data for products
// const products = [
//   {
//     id: 1,
//     category: "Development",
//     title: "Getting tickets to the big show",
//     imageUrl: product1, // Replace with your actual image path
//   },
//   {
//     id: 2,
//     category: "Development",
//     title: "Getting tickets to the big show",
//     imageUrl: product2, // Replace with your actual image path
//   },
//   {
//     id: 3,
//     category: "Development",
//     title: "Getting tickets to the big show",
//     imageUrl: product3, // Replace with your actual image path
//   },
//   {
//     id: 4,
//     category: "Development",
//     title: "Getting tickets to the big show",
//     imageUrl: product4, // Replace with your actual image path
//   },
//   {
//     id: 5,
//     category: "Development",
//     title: "Getting tickets to the big show",
//     imageUrl: product5, // Replace with your actual image path
//   },
//   {
//     id: 6,
//     category: "Development",
//     title: "Getting tickets to the big show",
//     imageUrl: product1, // Replace with your actual image path
//   },
// ];

// const Products = () => {
//   return (
//     <div className="px-8 pb-12">
//       <h1 className="text-6xl font-bold mt-16 text-center">Products</h1>
//       <p className="text-lg mb-16 mt-4 text-center">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
//       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12 lg:px-48 px-12">
//         {products.map((product) => (
//           <div
//             key={product.id}
//             className="cursor-pointer relative overflow-hidden group rounded-lg shadow-lg transition-transform transform hover:scale-105"
//           >
//             <img
//               src={product.imageUrl}
//               alt={product.title}
//               className="w-full h-[30rem] object-cover group-hover:opacity-50 transition-opacity"
//             />
//             <div className="absolute inset-0 bg-[#687ADA] opacity-10 group-hover:opacity-60 transition-opacity"></div>
//             <div className="absolute inset-0 flex flex-col items-start justify-end mb-12 ms-8 text-white text-start">
//               <h2 className="font-semibold">{product.category}</h2>
//               <p className="text-xl">{product.title}</p>
//               <button className="mt-3 px-4 py-2 border rounded border-white transition duration-300 hover:bg-[#687ADA] hover:border-[#687ADA]">
//                 View Details
//               </button>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Products;
import React from "react";
import smartDbProduct from "../assets/images/smartDb.jpg";
import seleneProduct from "../assets/images/selene.jpg";

const products = [
  {
    name: "Selene",
    description:
      "Selene is a powerful SaaS platform designed to simplify cloud deployments by providing industry-standard blueprints and customizable templates for Azure cloud. It ensures seamless, efficient, and reliable deployment processes, tailored to meet specific business needs. Coming soon! Register now for trial subscription access and experience enhanced cloud automation firsthand.",
    imageUrl:seleneProduct, // replace with your image path
  },
  {
    name: "SmartAI DB",
    description:
      "SmartAI DB is an AI-powered solution that seamlessly connects to multiple databases, enabling businesses to retrieve and analyze data with speed and precision. Leveraging advanced AI, it automates complex queries, delivers insightful results, and optimizes data management. Coming soon! Register now for early access and be the first to experience it.",
    imageUrl:  smartDbProduct, // replace with your image path
  },
];

const Products = () => {
  return (
    <div className="lg:px-48 px-12 pb-12">
      {/* <h1 className="text-6xl font-bold mt-16 text-center">Products</h1> */}
      <div className="my-48 space-y-48">
        {/* First Product */}
        <div className="flex flex-col md:flex-row items-center">
          <img
            src={products[0].imageUrl}
            alt={products[0].name}
            className="h-80 w-auto object-cover md:w-1/2 rounded-lg border-[0.5rem] border-[#687ADA]"
          />
          <div className="p-6 md:p-8 md:pl-12">
            <h2 className="text-4xl font-bold mb-2">{products[0].name}</h2>
            <p className="h-1 bg-[#687ADA] w-16 mb-4"></p>
            <p className="text-gray-700">{products[0].description}</p>
          </div>
        </div>

        {/* Second Product */}
        <div className="flex flex-col md:flex-row-reverse items-center">
          <img
            src={products[1].imageUrl}
            alt={products[1].name}
            className="h-80 w-auto object-cover md:w-1/2 rounded-lg border-[0.5rem] border-[#687ADA]"
          />
          <div className="p-6 md:p-8 md:pr-12">
            <h2 className="text-4xl font-bold mb-2">{products[1].name}</h2>
            <p className="h-1 bg-[#687ADA] w-16 mb-4"></p>
            <p className="text-gray-700">{products[1].description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
