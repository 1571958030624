import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
  FaYoutube,
  FaBlog,
} from "react-icons/fa";

const Footer = () => {
  const [authenticated, setAuthenticated] = useState(false);
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("authenticated");
    if (isLoggedIn) {
      setAuthenticated(isLoggedIn);
    }
  }, []);
  return (
    <footer className="bg-[#242A56] text-white pt-24 pb-8 lg:px-24 px-12">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* App Info Section */}
        <div>
          <h3 className="text-2xl font-semibold mb-4">About Our App</h3>
          <p className="text-gray-400 mb-6">
            We provide reliable and efficient software solutions tailored to
            your business needs. Our technology bridges the gap between you and
            success.
          </p>
          <p className="text-gray-400">© 2024 Cognire. All rights reserved.</p>
        </div>

        {/* Quick Links */}
        <div className="md:ml-8">
          <h3 className="text-2xl font-semibold mb-4">Quick Links</h3>
          <ul className="space-y-2">
            <li>
              <Link to="/" className="hover:underline">
                Home
              </Link>
            </li>
            <li>
              <Link to="/product" className="hover:underline">
                Product
              </Link>
            </li>
            <li>
              <Link to="/about" className="hover:underline">
                About
              </Link>
            </li>
            <li>
              <Link to="/contact" className="hover:underline">
                Contact Us
              </Link>
            </li>
            {!authenticated && <li>
              <Link to="/login" className="hover:underline">
                Login
              </Link>
            </li>}
          </ul>
        </div>

        {/* Contact Info */}
        <div>
          <h3 className="text-2xl font-semibold mb-4">Contact Us</h3>
          <ul className="space-y-2">
            <li className="text-gray-400">
              <span className="font-semibold">Email:</span> info@cognire.com
            </li>
            {/* <li className="text-gray-400">
              <span className="font-semibold">Phone:</span> +1 (234) 567-8901
            </li>
            <li className="text-gray-400">
              <span className="font-semibold">Location:</span> 123 Main St, Tech
              City
            </li> */}
          </ul>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="mt-12 border-t border-gray-700 pt-8 flex items-center justify-center gap-4">
        <p className="text-gray-500">Follow us on social media</p>
        <div className="flex justify-center space-x-4">
          {/* Social Media Icons */}
          <a
            href="https://www.youtube.com/@Cognire"
            target="_blank"
            className="hover:text-[#687ADA] transition-transform transform hover:-translate-y-1"
            aria-label="Facebook"
          >
            <FaYoutube size={24} />
          </a>
          <a
            href="https://www.linkedin.com/in/cognire-solution-b26a5b330/"
            target="_blank"
            className="hover:text-[#687ADA] transition-transform transform hover:-translate-y-1"
            aria-label="LinkedIn"
          >
            <FaLinkedinIn size={24} />
          </a>
          <a
            href="https://www.linkedin.com/in/cognire-solution-b26a5b330/recent-activity/all/"
            target="_blank"
            className="hover:text-[#687ADA] transition-transform transform hover:-translate-y-1"
            aria-label="Instagram"
          >
            <FaBlog size={24} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
