import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/logos/header-logo.jpg";
import { RxHamburgerMenu } from "react-icons/rx";
import { toast } from "react-toastify";
import { FaBlog, FaLinkedinIn, FaYoutube } from "react-icons/fa";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const navItems = [
    { name: "Home", path: "/" },
    { name: "Product", path: "/product" },
    { name: "About", path: "/about" },
    { name: "Services", path: "/services" },
    { name: "Contact Us", path: "/contact" },
  ];

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("authenticated");
    if (isLoggedIn) {
      setAuthenticated(isLoggedIn);
    }
  }, []);

  const handleLogoutClick = () => {
    toast.success("Logout successfully.");
    localStorage.removeItem("authenticated");
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  return (
    <header className={`py-6 lg:px-24 px-12 border-b border-[#EFEEED]`}>
      <nav className="flex justify-between items-center">
        <div className="text-xl font-bold">
          <NavLink to="/">
            <img src={logo} alt="Cognire Logo" className="h-12" />
          </NavLink>
        </div>

        {/* Desktop Menu */}
        <ul className="hidden md:flex items-center gap-8 text-lg">
          {navItems.map((item) => (
            <li key={item.name}>
              <NavLink
                to={item.path}
                className={({ isActive }) =>
                  isActive
                    ? "text-[#687ADA] border-b-2 border-[#687ADA] pb-1"
                    : "text-gray-600 hover:text-[#687ADA]"
                }
              >
                {item.name}
              </NavLink>
            </li>
          ))}
          {!authenticated ? (
            <li key={"Login"}>
              <NavLink
                to={"/login"}
                onClick={() => setIsMenuOpen(false)} // Close the menu after clicking a link
                className={({ isActive }) =>
                  isActive
                    ? "text-[#687ADA] font-semibold border-b-2 border-[#687ADA] pb-1"
                    : "text-gray-600 hover:text-[#687ADA]"
                }
              >
                Login
              </NavLink>
            </li>
          ) : (
            <button
              className="hover:bg-red-200 px-2 py-2 rounded-xl"
              onClick={() => handleLogoutClick()}
            >
              Logout
            </button>
          )}
        </ul>

        <div className="flex items-center justify-center gap-4">
          <div className="flex justify-center space-x-4">
            {/* Social Media Icons */}
            <a
              href="https://www.youtube.com/@Cognire"
              target="_blank"
              className="hover:text-[#687ADA] transition-transform transform hover:-translate-y-1"
              aria-label="Facebook"
            >
              <FaYoutube size={24} />
            </a>
            <a
              href="https://www.linkedin.com/in/cognire-solution-b26a5b330/"
              target="_blank"
              className="hover:text-[#687ADA] transition-transform transform hover:-translate-y-1"
              aria-label="LinkedIn"
            >
              <FaLinkedinIn size={24} />
            </a>
            <a
              href="https://www.linkedin.com/in/cognire-solution-b26a5b330/recent-activity/all/"
              target="_blank"
              className="hover:text-[#687ADA] transition-transform transform hover:-translate-y-1"
              aria-label="Instagram"
            >
              <FaBlog size={24} />
            </a>
          </div>
        </div>

        {/* Hamburger Menu Button (for small screens) */}
        <div className="md:hidden flex items-center">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="text-gray-600 focus:outline-none"
          >
            <RxHamburgerMenu className="text-2xl" />
          </button>
        </div>

        {/* Mobile Menu (toggle visibility based on state) */}
        {isMenuOpen && (
          <div className="md:hidden absolute top-16 left-0 right-0 bg-white shadow-md z-50 p-4">
            <ul className="flex flex-col gap-4">
              {navItems.map((item) => (
                <li key={item.name}>
                  <NavLink
                    to={item.path}
                    onClick={() => setIsMenuOpen(false)} // Close the menu after clicking a link
                    className={({ isActive }) =>
                      isActive
                        ? "text-[#687ADA] font-semibold border-b-2 border-[#687ADA] pb-1"
                        : "text-gray-600 hover:text-[#687ADA]"
                    }
                  >
                    {item.name}
                  </NavLink>
                </li>
              ))}
              {!authenticated ? (
                <li key={"Login"}>
                  <NavLink
                    to={"/login"}
                    onClick={() => setIsMenuOpen(false)} // Close the menu after clicking a link
                    className={({ isActive }) =>
                      isActive
                        ? "text-[#687ADA] font-semibold border-b-2 border-[#687ADA] pb-1"
                        : "text-gray-600 hover:text-[#687ADA]"
                    }
                  >
                    Login
                  </NavLink>
                </li>
              ) : (
                <button
                  className="text-left hover:bg-red-200 px-2 py-2 text-gray-600 hover:text-[#687ADA]"
                  onClick={() => handleLogoutClick()}
                >
                  Logout
                </button>
              )}
            </ul>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;
