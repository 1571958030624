import React from "react";
import aboutImage from "../assets/images/about-us.jpg";
import aboutContactImage from "../assets/images/about-us-contact.jpg";
import sixDProcessImage from "../assets/images/6D-process.jpg";
import { useNavigate } from "react-router-dom";

const AboutPage = () => {
  const navigate = useNavigate();
  const handleRedirectContactus = (e) => {
    e.preventDefault();
    navigate("/contact");
  };
  return (
    <div className="w-full">
      {/* Main Content Section */}
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between px-6 lg:px-24 my-16 gap-12">
        {/* Image Section */}
        <div className="lg:w-1/2">
          <img
            src={aboutImage}
            alt="About Image"
            className="rounded-lg shadow-lg"
          />
        </div>

        {/* Text Section */}
        <div className="lg:w-1/2">
          <h2 className="text-4xl font-bold text-gray-800 mb-4">About Us</h2>
          <p className="text-gray-600 text-base mb-6">
            At Cognire, we deliver cutting-edge cloud solutions, AI-driven
            technologies, and robust security frameworks, backed by a team of
            industry-experienced experts. Our software products empower
            businesses to innovate, streamline operations, and ensure data
            protection through scalable cloud infrastructure, intelligent
            automation, and advanced security measures. Partner with Cognire for
            transformative digital success.
          </p>
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            Who we are
          </h3>
          <p className="text-gray-600 text-base mb-6">
            Experienced industry experienced professionals with years of
            experience in product development, consulting services, leading
            agile team and provide value based software services across globe.
            We have advisor from Microsoft , Amazon like tier 1 companies. Our
            partners are based out Asia and Europe and we offer global services.
          </p>
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            Our Mission
          </h3>
          <p className="text-gray-600 text-lg mb-6">
            Deliver excellence in software based service product and services
            with highest quality.
          </p>
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            What We Do
          </h3>
          <ul className="text-gray-600 text-base mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 list-disc pl-5">
            <li>Tech Support</li>
            <li>Cloud Services</li>
            <li>DevOps Services</li>
            <li>Data Science and NLP</li>
            <li>Salesforce Development</li>
            <li>Container Technology</li>
            <li>Azure, AWS, and GCP Migration</li>
            <li>Azure, AWS, and GCP Cloud Data Services</li>
            <li>Cloud Infrastructure and Automation</li>
          </ul>
        </div>
      </div>

      {/* Divider Section */}
      {/* <h2 className="text-3xl font-bold text-center text-gray-800 my-12 w-max mx-auto">
        Our 6-D Process
        <p className="h-1 bg-[#687ADA] mx-auto w-16 mt-2"></p>
      </h2>
      <div className="px-4">
        <img
          src={sixDProcessImage}
          alt="Descriptive Alt Text"
          className="lg:h-[40rem] w-auto lg:object-cover rounded-lg shadow-lg mx-auto"
        />
      </div> */}
      {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mx-auto px-6 lg:px-32 pb-12">
        {["Discover", "Define", "Design", "Develop", "Deploy", "Deliver"].map(
          (item, index) => (
            <div
              className="text-center transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
              key={index}
            >
              <span className="text-6xl font-extrabold text-[#687ADA] opacity-30 hover:opacity-100 transition-opacity duration-300">
                {String(index + 1).padStart(2, "0")}.
              </span>
              <h3 className="text-2xl font-semibold text-gray-800 mt-2 pl-12">
                {item}
              </h3>
            </div>
          )
        )}
      </div> */}

      <div className="py-12 mx-auto max-w-7xl lg:px-0 px-12">
        <div className="lg:relative flex flex-col lg:flex-row items-center">
          {/* Image Section */}
          <div className="">
            <img
              src={aboutContactImage}
              alt="Descriptive Alt Text"
              className="w-full h-94 object-cover rounded-lg shadow-lg scale-x-[-1]"
            />
          </div>

          {/* Text Section */}
          <div className="lg:w-1/2 lg:px-6 px-6 lg:ml-8 p-6 lg:absolute bg-gray-900 rounded-2xl lg:top-2/4 top-1/2 transform lg:translate-y-0 translate-y-[-50%]">
            <h2 className="text-4xl font-bold text-white mb-4">
              Why Choose Us?
            </h2>
            {/* <p className="text-gray-400 text-lg mb-6">
              We help you turn your concepts into reality. Our team of experts
              is dedicated to providing you with innovative solutions that meet
              your needs.
            </p> */}
            <ul className="text-gray-300 text-base mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 list-disc pl-5">
              <li>Tech Support</li>
              <li>Cloud Services</li>
              <li>DevOps Services</li>
              <li>Data Science and NLP</li>
              <li>Salesforce Development</li>
              <li>Container Technology</li>
            </ul>
            <button
              className="bg-[#687ADA] text-white py-2 px-6 rounded-md hover:bg-blue-600 transition duration-300"
              onClick={(e) => handleRedirectContactus(e)}
            >
              Contact us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
