import {
  FaCode,
  FaComments,
  FaDatabase,
  FaLifeRing,
  FaShoppingCart,
} from "react-icons/fa";
import { HiOutlinePencilSquare } from "react-icons/hi2";

const ServicesPage = () => {
  const services = [
    {
      id: 1,
      icon: <HiOutlinePencilSquare className="text-6xl mb-2" />,
      title: "Design",
      description:
        "Creating visually appealing designs that captivate audiences.",
    },
    {
      id: 2,
      icon: <FaCode className="text-6xl mb-2" />,
      title: "Software Development",
      description: "Building robust software solutions tailored to your needs.",
    },
    {
      id: 3,
      icon: <FaComments className="text-6xl mb-2" />,
      title: "Consulting",
      description:
        "Providing expert advice to enhance your business performance.",
    },
    {
      id: 4,
      icon: <FaDatabase className="text-6xl mb-2" />,
      title: "Data & AI",
      description: "Leveraging data analytics and AI to drive smart decisions.",
    },
    {
      id: 5,
      icon: <FaShoppingCart className="text-6xl mb-2" />,
      title: "eCommerce",
      description:
        "Developing effective eCommerce platforms for your business.",
    },
    {
      id: 6,
      icon: <FaLifeRing className="text-6xl mb-2" />,
      title: "Help & Support",
      description:
        "Offering reliable support and assistance whenever you need.",
    },
  ];
  return (
    <div className="w-full">
      <h2 className="text-4xl font-bold text-center text-gray-800 py-12 w-full mx-auto">
        How can we help you?
        <p className="h-1 bg-[#687ADA] mx-auto w-16 mt-2"></p>
      </h2>
      <div className="w-full bg-[#F6F6F6] py-4">
        <p className="w-full lg:px-48 px-12 my-8 mx-auto text-center">
          We offer custom IT solutions with expert technical skills to solve
          complex issues and adapt to your business needs. Our goal is to help
          you achieve sustainable growth and optimize your processes. Partner
          with us to work with professionals solely dedicated to realizing your
          business objectives. Expect exceptional outcomes.
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 py-12 lg:px-48 px-12">
        {services.map((service) => (
          <div
            key={service.id}
            className="group bg-[#F6F6F6] rounded-lg p-8 shadow-md hover:shadow-lg transition-transform duration-300 hover:-translate-y-6 cursor-pointer hover:bg-[#687ADA] hover:text-white"
          >
            {/* Icon */}
            <div className="flex hover:text-white">
              {/* Change icon color on hover */}
              <span className="text-[#687ADA] group-hover:text-white transition-colors duration-300">
                {service.icon}
              </span>
            </div>
            {/* Title */}
            <h3 className="text-2xl font-bold mt-4 mb-2">{service.title}</h3>
            {/* Description */}
            <p className="">{service.description}</p>
          </div>
        ))}
      </div>
      
    </div>
  );
};
export default ServicesPage;
