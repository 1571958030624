import { env } from "./EnvironmentConfig";

export const APP_NAME = "Cognire";
export const API_BASE_URL = env.API_ENDPOINT_URL + "/api/v1";
export const GIT_CLIENT_ID = "Ov23liRRKuvWIpGXyUS8";
export const GOOGLE_CLIENT_ID = "355570558881-jotgjkjhmc7it2vnvbfj2fcbotrk99mh.apps.googleusercontent.com";
export const GOOGLE_RECAPTCH_KEY = "6LetOXAqAAAAAOYdmwfxh2HXLfVrclxHm3iZydgI";
// export const GOOGLE_RECAPTCH_KEY = "6Ld4c24qAAAAAG7YMtYmxrYH-KEROrLJTVNaghRE";
// export const GIT_CLIENT_ID = "Ov23li3t0rN1DUFsKVel";
// export const GOOGLE_CLIENT_ID = "848347895228-fjf14h6cnq345t8v94646l0mlf0m0akk.apps.googleusercontent.com";
export const BASE_URL = env.BASE_URL;
