import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import LoginPage from "./pages/Login";
import SignupPage from "./pages/Signup";
import ServicesPage from "./pages/Services";
import AboutPage from "./pages/about";
import Products from "./pages/Products";
import ContactUs from "./pages/Contact";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgetPassword from "./pages/ForgetPassword";

function App() {
  return (
    <Router>
      <ToastContainer />
      <Header />
      <div className="">
        <Routes>
          <Route path="*" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forget/password" element={<ForgetPassword />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/product" element={<Products />} />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
