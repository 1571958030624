import { useGoogleLogin } from "@react-oauth/google";
import React, { useState } from "react";
import { FaGoogle, FaGithub, FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import JwtAuthService from "../services/authService";
import { toast } from "react-toastify";
import { API_BASE_URL, GIT_CLIENT_ID, GOOGLE_RECAPTCH_KEY } from "../config/AppConfig";
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const navigate = useNavigate();
  const [captchaValue, setCaptchaValue] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value); // Set CAPTCHA value when completed
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    // Validate email
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }

    // Validate password
    if (!formData.password) {
      newErrors.password = "Password is required";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      JwtAuthService.login(formData)
        .then((response) => {
          console.log("response = ", response);
          if (response.success) {
            toast.success(response.message);
            localStorage.setItem("authenticated", true);
            setTimeout(() => {
              window.location.href = "/";
            }, 1500);
          }
        })
        .catch((error) => {
          console.log("error = ", error);
          if (error.message) {
            toast.error(error.message);
          }
        });
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      // Fetch user information from Google
      const userInfoResponse = await fetch(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
          },
        }
      );

      if (userInfoResponse.ok) {
        const userData = await userInfoResponse.json();
        // Prepare data to send to your backend
        const dataToSend = {
          email: userData.email,
          firstname: userData.given_name, // Assuming Google returns first name
          lastname: userData.family_name ?? "", // Use empty string if not available
          profile_picture: userData.picture, // Assuming Google returns profile picture
          provider: "google",
        };

        // Call your JWT authentication service to log in
        try {
          const resp = await JwtAuthService.socialLogin(dataToSend);
          console.log("Response from backend:", resp);
          if (resp.success) {
            toast.success(resp.message);
            localStorage.setItem("authenticated", true);
            setTimeout(() => {
              window.location.href = "/";
            }, 1500);
            // navigate("/");
          }
        } catch (error) {
          console.error("Error during login:", error);
        }
      } else {
        console.error(
          "Error fetching user info:",
          await userInfoResponse.text()
        );
      }
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  // Redirect to GitHub login
  const handleGithubLogin = () => {
    const clientId = GIT_CLIENT_ID; // Your GitHub client ID
    const redirectUri = `${API_BASE_URL}/user/git/login`; // Your backend endpoint

    // Redirect to GitHub's OAuth 2.0 authorization page
    window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&scope=user:email`;
  };

  const handleNavigateFogetPassword = (e) => {
    e.preventDefault();
    navigate("/forget/password");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 px-4">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
        <h2 className="text-3xl font-bold mb-6 text-center">Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={`w-full px-4 py-2 border ${
                errors.email ? "border-red-500" : "border-gray-300"
              } rounded-lg focus:outline-none focus:ring-2 focus:ring-[#687ADA]`}
              placeholder="Enter your email"
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">{errors.email}</p>
            )}
          </div>
          <div className="mb-6 relative">
            <label className="block text-gray-700">Password</label>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              className={`w-full px-4 py-2 border ${
                errors.password ? "border-red-500" : "border-gray-300"
              } rounded-lg focus:outline-none focus:ring-2 focus:ring-[#687ADA]`}
              placeholder="Enter your password"
            />
            <div
              className="absolute right-3 top-1/2 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <FaEyeSlash className="text-gray-600 text-xl" />
              ) : (
                <FaEye className="text-gray-600 text-xl" />
              )}
            </div>
            {errors.password && (
              <p className="text-red-500 text-sm mt-1">{errors.password}</p>
            )}
          </div>
          <div className="mb-4">
            <ReCAPTCHA
              sitekey={GOOGLE_RECAPTCH_KEY} // Replace with your site key
              onChange={handleCaptchaChange}
            />
          </div>
          <button
            type="submit"
            className={`w-full bg-[#687ADA] text-white py-2 rounded-lg  transition duration-300 ${
              !captchaValue
                ? "cursor-not-allowed"
                : "cursor-pointer hover:bg-[#5a69c1]"
            }`}
            disabled={!captchaValue}
          >
            Login
          </button>
        </form>

        <div className="flex items-center justify-center mt-4 space-x-4">
          <button
            className="flex items-center bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 text-sm"
            onClick={(e) => {
              e.preventDefault();
              handleGoogleLogin(e);
            }}
          >
            <FaGoogle className="mr-2 text-xl" /> Login with Google
          </button>
          <button
            className="flex items-center bg-gray-800 text-white px-4 py-2 rounded-lg hover:bg-gray-900 text-sm"
            onClick={handleGithubLogin}
          >
            <FaGithub className="mr-2 text-xl" /> Login with GitHub
          </button>
        </div>
        <div className="mt-6 flex items-center text-sm justify-between">
          <p className="text-center">
            Don't have an account?{" "}
            <Link to="/signup" className="text-[#687ADA] hover:underline">
              Sign Up
            </Link>
          </p>
          <p
            className="w-fit ml-auto text-[#687ADA] hover:underline cursor-pointer"
            onClick={(e) => handleNavigateFogetPassword(e)}
          >
            Forgot password
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
